import styled from "styled-components";

const OpaqueOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2;
  opacity: 0;
  transition: all 0.3s ease 0s;
`;

export default OpaqueOverlay;
