import { useState, useEffect } from "react";
import debounce from "lodash.debounce";

import getAuthor from "../logic/get-author";
import { getKeywords } from "../logic/keywords";
import isDraftPost from "../logic/isDraftPost";

const useSearch = ({ location, posts }) => {
  const [results, setResults] = useState(posts);
  const searchQuery = (
    new URLSearchParams(location.search).get("keywords") || ""
  ).toLowerCase();

  useEffect(
    debounce(() => {
      let blogPosts = posts;
      if (searchQuery) {
        blogPosts = posts.filter(({ node }) => {
          const keywords = getKeywords(node.childMarkdownRemark.html);
          if (isDraftPost(node.title)) {
            return false;
          } else if (node.title.toLowerCase().indexOf(searchQuery) > -1) {
            return true;
          } else if (
            searchQuery === "sheila" &&
            getAuthor(node.owner) === "Sheila Murray"
          ) {
            return true;
          } else if (
            searchQuery === "james" &&
            getAuthor(node.owner) === "James Simone"
          ) {
            return true;
          } else if (keywords && keywords.indexOf(searchQuery) > -1) {
            return true;
          } else {
            return (
              node.childMarkdownRemark.excerpt
                .toLowerCase()
                .indexOf(searchQuery) > -1
            );
          }
        });
      }
      setResults(blogPosts);
    }, 500),
    [posts, searchQuery]
  );
  return { results, searchQuery };
};

export default useSearch;
