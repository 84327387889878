import Img from "gatsby-image";
import kebabCase from "lodash.kebabcase";
import React from "react";
import styled from "styled-components";

import CenteredText from "./centered-text";
import Constants from "../constants";
import InternalLink from "./internal-link";
import OpaqueOverlay from "./opaque-overlay";
import RecipeMetadata from "./recipe-metadata";

const RecipeHeadline = styled.h2`
  margin-top: 10px;
  font-size: 20px;

  @media (max-width: ${Constants.mobileWidth}) {
    font-size: 15px;
  }
`;

const RecipeText = styled.p`
  margin-top: 10px;
`;

const Wrapper = styled(InternalLink)`
  overflow: hidden;
  > div img {
    transition: all 0.3s ease 0s !important;
  }
  padding: 0 10px;
  position: relative;
  &:hover {
    ${OpaqueOverlay} {
      opacity: 1;
    }
  }
`;

const RecipeWrapper = ({ recipe, showText }) => {
  const coverPhoto = recipe.data.Photos && recipe.data.Photos.localFiles[0];
  return (
    <Wrapper
      itemType="https://schema.org/Recipe"
      itemScope={true}
      to={`/recipes/${kebabCase(recipe.data.Name)}/`}
    >
      <RecipeMetadata recipe={recipe} />
      {recipe.data.Photos && (
        <Img
          title={`${recipe.data.Name.trim()} cover photo`}
          fluid={[
            coverPhoto.childImageSharpMobile.fluid,
            {
              ...coverPhoto.childImageSharpDesktop.fluid,
              media: `min-width: 900`
            }
          ]}
          alt={`${recipe.data.Name.trim()} cover photo`}
          itemProp="image"
          loading={!showText ? "eager" : "lazy"}
          style={{ maxHeight: 250 }}
        />
      )}
      <CenteredText>
        <RecipeHeadline itemProp="headline name" as={!showText && "h3"}>
          {recipe.data.Name}
        </RecipeHeadline>
      </CenteredText>
      {showText && <RecipeText>{recipe.data.Subheadline}</RecipeText>}

      <OpaqueOverlay />
    </Wrapper>
  );
};

export default RecipeWrapper;
