import { Grid } from "grommet";
import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

import BlogArticle from "./blog-article";
import Constants from "../constants";
import ContentBreak from "./content-break";
import Layout from "./layout";
import isDraftPost from "../logic/isDraftPost";
import massReplacement from "../logic/massReplacement";
import RecipeWrapper from "./recipe-wrapper";
import replaceFootnotes from "../logic/replaceFootnotes";
import SearchForm from "./search-form";
import SEO from "./seo";
import useSearch from "../hooks/useSearch";
import CenteredText from "./centered-text";

const BlogPageAnchor = styled(Link)`
  background-color: ${Constants.Colors.headerTheme};
  box-shadow: none;
  color: #fff;
  display: block;
  margin: 0 7px 10px 0;
  padding: 3px;
  text-decoration: none;
  text-align: center;

  @media (max-width: ${Constants.mobileWidth}) {
    &::after {
      content: " →";
    }
  }
`;

const LinkToPost = styled(Link)`
  color: #000;

  box-shadow: none;
`;

const BlogPostList = styled.ol`
  list-style-type: none;
  margin: 0;
`;

const BlogPostListing = styled.li`
  padding: 0;
  margin-bottom: 0.5rem;
`;

const BlogPosts = ({ avatar, logo, posts }) => {
  return (
    posts &&
    posts.map(({ node }, index) => {
      if (isDraftPost(node.title)) {
        return null;
      }

      let formattedExcerpt = massReplacement({
        html: node.FullExcerpt.childMarkdownRemark.html,
        videoReplacementText: "[Go to post to watch the video!]"
      })
        .replace(/<sup.+?>/g, "")
        .replace(/<h.>/g, `<h3 style="font-size: 1rem">`)
        .replace(/<\/h.>/g, "</h3>")
        .replace(/<a href="#fn-.+?>.<\/a>/g, "*");

      return (
        <BlogPostListing key={node.id}>
          <LinkToPost to={node.fields.slug}>
            <BlogArticle
              avatar={avatar}
              html={formattedExcerpt}
              logo={logo}
              isFirst={index === 0}
              post={node}
              title={replaceFootnotes(node.title)}
            />
            <ContentBreak />
          </LinkToPost>
        </BlogPostListing>
      );
    })
  );
};

const PostHeadline = styled.h2`
  color: ${Constants.Colors.headerTheme};
  font-size: 1.5rem;
  margin-bottom: 0;
  :hover {
    color: ${Constants.Colors.headerGray};
  }

  &:first-child {
    margin-top: 1rem;
  }
`;

const RecipeSection = ({ recipes }) => {
  return (
    <>
      <LinkToPost to={"/recipes/"}>
        <CenteredText>
          <PostHeadline>Our recent recipes</PostHeadline>
        </CenteredText>
      </LinkToPost>
      <Grid gap={"small"} columns={[`1fr`, `1fr`]} margin="1rem 0 0 0">
        {recipes.edges.length > 0 &&
          recipes.edges.map(recipe => (
            <RecipeWrapper
              key={recipe.node.id}
              recipe={recipe.node}
              showText={false}
            />
          ))}
      </Grid>
    </>
  );
};

const BlogPage = ({
  avatar,
  backdrop,
  location,
  logo,
  posts,
  recipes,
  showAll,
  showSearch,
  site,
  title
}) => {
  const { results, searchQuery } = useSearch({ location, posts });

  return (
    <Layout avatar={avatar} backdrop={backdrop} location={location} site={site}>
      <SEO
        avatar={avatar}
        logo={logo}
        isHomePage={showAll}
        site={site}
        title={title}
      />
      {showAll && <RecipeSection recipes={recipes} />}
      {showSearch && <SearchForm searchQuery={searchQuery} />}
      <BlogPostList>
        <BlogPosts avatar={avatar} logo={logo} posts={results} />
      </BlogPostList>
      {showAll && (
        <BlogPageAnchor to="/blog">See All Our Posts!</BlogPageAnchor>
      )}
    </Layout>
  );
};

export default BlogPage;
