const { keywordsRegex } = require("./keywords");

const massReplacement = ({ html, videoReplacementText }) => {
  return html
    .replace(/<h1>.+?<\/h1>/, "")
    .replace(keywordsRegex, "")
    .replace(/\[video .+?\]/g, videoReplacementText);
};

module.exports = massReplacement;
