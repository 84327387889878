import { graphql } from "gatsby";
import React from "react";

import BlogPage from "../components/blog-page";

const BlogIndex = ({
  data: { avatar, backdrop, blogPosts, logo, recipes, site },
  location
}) => {
  const { subTitle, title } = site.siteMetadata;

  return (
    <BlogPage
      avatar={avatar}
      backdrop={backdrop}
      location={location}
      logo={logo}
      posts={blogPosts.edges}
      recipes={recipes}
      showAll={true}
      site={site}
      title={title + ` - ` + subTitle}
    />
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      ...Avatar
    }

    backdrop: file(absolutePath: { regex: "/mountains.png/" }) {
      ...Backdrop
    }

    blogPosts: allDropboxPaperDocumentNew(
      sort: { fields: created_date, order: DESC }
      filter: { fields: { status: { eq: "active" } } }
      limit: 5
    ) {
      ...BlogPostFieldsFull
    }

    logo: file(absolutePath: { regex: "/she-and-jim-logo.jpg/" }) {
      ...Logo
    }

    recipes: allAirtable(
      filter: { table: { eq: "Recipes" } }
      sort: { fields: data___PublishDate, order: DESC }
      limit: 2
    ) {
      edges {
        node {
          data {
            Photos {
              localFiles {
                childImageSharpMobile: childImageSharp {
                  fluid(
                    quality: 100
                    maxHeight: 300
                    maxWidth: 300
                    srcSetBreakpoints: [480, 900]
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
                childImageSharpDesktop: childImageSharp {
                  fluid(
                    quality: 100
                    maxHeight: 400
                    maxWidth: 400
                    srcSetBreakpoints: [480, 900]
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ...RecipeData
        }
      }
    }

    site {
      siteMetadata {
        authors
        social {
          instagram
        }
        siteUrl
        subTitle
        title
      }
    }
  }
`;
