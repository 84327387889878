import { navigate } from "gatsby";
import React from "react";
import styled from "styled-components";

import Constants from "../constants";

const Form = styled.form`
  display: flex;
  margin: 1rem 0;
`;

const Label = styled.label`
  display: inline;
`;

const Input = styled.input`
  display: inline;
  border: 1px solid ${Constants.Colors.headerGray};
  border-radius: 5px;
  margin: 0 0 0 5px;
`;

const SearchForm = ({ searchQuery }) => (
  <Form role="search" method="GET">
    <Label htmlFor="search-input">
      <strong>Search posts</strong>
    </Label>
    <Input
      type="search"
      id="search-input"
      name="keywords"
      aria-controls="search-results-count"
      onChange={e =>
        !e.target.value
          ? navigate(`/blog`)
          : navigate(`/blog?keywords=${encodeURIComponent(e.target.value)}`)
      }
      value={searchQuery}
    />
  </Form>
);

export default SearchForm;
